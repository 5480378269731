import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input,FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row, Label } from 'reactstrap';
import { firebaseConnect,firestoreConnect,withFirebase } from 'react-redux-firebase'
//import {receiveLogin,receiveRole} from '../../../actions/actions';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import {
  compose
} from 'recompose';

/* const options = [
  { value: 'SEDE PRINCIPAL', label: 'SEDE PRINCIPAL' },
  { value: 'SEDE CHULUAMBO', label: 'SEDE CHULUAMBO' },
  { value: 'SEDE CABUYAL', label: 'SEDE CABUYAL' },
  { value: 'SEDE ALTAMIRA', label: 'SEDE ALTAMIRA' },
  { value: 'SEDE DANTAS', label: 'SEDE DANTAS' },
  { value: 'SEDE GOLONDRINAS', label: 'SEDE GOLONDRINAS' },
  { value: 'SEDE EL PALMAR', label: 'SEDE EL PALMAR' },
]; */

class Login extends Component {
  state={checked:false, email:"",password:"", open:false};

  constructor(props){
    super(props);
    this.auth = this.props.firebase.auth();
    this.db = this.props.firebase.firestore();
  }

  
  handleCheck=()=> {
    this.setState({checked: !this.state.checked});
  }






  login = async event => {

    event.preventDefault();

    if(this.state.email!="" && this.state.password!=""){
      var checkState = this.db.collection("usuarios").where("email","==",this.state.email);
    
        await checkState.get().then(snap=>{
          let cont=[]
            snap.forEach( (doc) => {
              cont.push(doc)
              let user = JSON.parse(JSON.stringify(doc.data()));
              if(user.estado=='Activo'){
                try {
                  this.props.firebase
                   .auth()
                   .signInWithEmailAndPassword(this.state.email.trim(), this.state.password)
                   .then(()=>{
                           let userCurrent = this.props.firebase.auth().currentUser;
                           userCurrent.getIdTokenResult().then((idTokenResult) => {
                              let role= idTokenResult.claims.adminMaster?
                              "adminMaster":idTokenResult.claims.admin?
                                 "admin":(idTokenResult.claims.estudiante
                                   ?
                                     "estudiante":(idTokenResult.claims.profesor
                                           ?
                                           "profesor":"profesorTutor"));
                                if((role==="adminMaster" || role==="admin" || role==="estudiante" || role==="profesor")){
                                     this.setState({
                                       email: '', password: '', error: '', loading: false
                                     });
                                     this.props.history.push("/"); 
                                 }else{

                                     this.setState({
                                       error: "El usuario no tiene acceso a la plataforma", loading: false
                                     });
                                 }
                          
                           })
                           .catch((error) => {
                            this.setState({
                              error: "Correo o contraseña erroneos", loading: false
                            });
                           });
                   })
                   .catch((error) => {
                     if(error.message==='Too many unsuccessful login attempts. Please try again later.'){
                      this.setState({
                        error: "Relizaste muchos intentos, Intentalo de nuevo en unos minutos", loading: false
                      });
                     }else{
                     this.setState({
                       error: "Correo o contraseña erroneos", loading: false
                     });
                    }
                   });
                  
               }catch(error) {
                 this.setState({
                  error: "Correo o contraseña erroneos", loading: false
                });
               }
              }else{
                ToastsStore.error("Su usuario ha sido inhabilitado!")
              }


            })
            if(cont.length===0){
              ToastsStore.error("Correo o contraseña erroneas")
            }

        }).catch(err=>{
          ToastsStore.error("Correo o contraseña erroneas")
        })

      }else{
        ToastsStore.error("Ingrese el correo y la contraseña!")
      }
    } 

    keyPressed(event) {
      if (event.key === "Enter") {
        this.login(event)
      }
    }
    keyPressed2(event) {
      if (event.key === "Enter") {
        document.getElementById('inp-pass').focus()
      }
    }

    onLoginSuccess() {
      
    }
    onLoginFailure(errorMessage) {
      this.setState({ error: errorMessage, loading: false })
    }



  render() {
       return (  <div className="app flex-row align-items-center loginpage" >
           <div className="contentlogin"  style={{ backgroundColor: 'gray', backgroundPosition: 'center',backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          
              
       
          {this.getModal()}
          <ToastsContainer store={ToastsStore}/>
          <Container style={{paddingLeft:"15vw !important"}}>
           
                  
          <Row className="justify-content-center">
            <Col md="8" lg="10">
              <CardGroup className="card-color" >
                <Card className="py-8 card-color2 " >
                  <CardBody className="card-body">
                    <Form >
                      <h1 className="title-login">Institución Educativa Quichaya</h1>
                      <h4 className="text-login">Ingresa »</h4>
                      {/* <FormGroup>
                        <InputGroup className="mb-3 login-input-group" >
                          <Select id="cursosDirectorChange"
                            closeMenuOnSelect={true}
                            options={options}
                            onChange={this.onChangeOptions}
                            value={this.state.optionSede}
                            placeholder="Selecciona la sede"
                            
                          />
                          </InputGroup>
                     </FormGroup> */}
                      <FormGroup>
                        <InputGroup className="mb-3 login-input-group" >
                          <Input type="text" className="input" value={this.state.email} 
                          ref='email' 
                          onKeyPress={this.keyPressed2}
                          onChange={(event)=>this.setState({email:event.target.value})}  placeholder="Usuario" name="correo"   required/>
                        </InputGroup>
                     </FormGroup>
                     <FormGroup style={{marginBottom: "0px !important"}}>
                        <InputGroup className="login-input-group">
                          <Input type="password" className="input" id="inp-pass"
                          ref='password' value={this.state.password} onKeyPress={(e)=>this.keyPressed(e)} onChange={(event)=>this.setState({password:event.target.value})} placeholder="Contraseña" autoComplete="current-password" required/>
                        </InputGroup>
                    </FormGroup>

                     <Row style={{marginTop:"8px"}}>
                        <Col xs="4" style={{paddingRight:0}}>

                      
                        </Col>
                        <Col xs="8" className="text-right text-recover" >
                            <Button className="button_recover" onClick={this.onOpenModal} >
                                ¿Olvidó su contraseña?
                            </Button>    
                        </Col>
                      </Row>

                       <div style={{color:"#b8c052"}}>{this.state.error}</div>
                       <Row style={{marginTop:"8px"}}>
                        <Col xs="6" style={{paddingRight:0}}>

                           <Button onClick={this.login} className="button-login login-principal mb-3"  >Entrar</Button>

                        </Col>

                      </Row>
                      
                    



                    </Form>
                  </CardBody>
                </Card>
                
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <img style={{maxWidth:'150px'}} src={"../../../assets/img/logo/escudo.png"}></img>
        </div>
       
       
       </div>);
    
  }

/*   onChangeOptions= value =>{
    this.setState({optionSede:value})
  }
 */


  getModal(){
      return(
        <Modal open={this.state.open} onClose={this.onCloseModal} style={{width:"45%"}}>
        <div className="mapDialog">

        <Container style={{padding:"10px"}}>

        <br/>
          <Row className="justify-content-center">
         

            <Form  onSubmit={this.recoverPassword} >
             
                      <center><h4 className="text-dialog-recover">¿Has olvidado la contraseña?</h4></center>
                      <FormGroup>
                        <InputGroup className="mb-3 login-input-group" >
                         
                          <Input type="text" className="input" value={this.state.email}   onChange={(event)=>this.setState({email:event.target.value})}  placeholder="Escribe tu correo electrónico" autoComplete="username" required/>
                        </InputGroup>
                     </FormGroup>
                     <div style={{width:"100%", textAlign:"center", marginTop:"25px", marginBottom:"20px"}}>
                       <Button type="submit" color="primary" style={{width:"200px", margin:"0 auto"}} className="button-login "  block>Restablecer contraseña</Button>
                    </div> 
                   
            
            </Form>
          </Row>
          <br/>
         </Container>       
        </div>              
      </Modal>);
  }
  onOpenModal=(event)=>{
    event.preventDefault();
    this.setState({ open: true });
  };
 
  onCloseModal = (event) => {
    event.preventDefault();
    this.setState({ open: false });
  };


  recoverPassword=(event)=>{


     event.preventDefault();
     let comp=this; 
     this.props.firebase.auth().sendPasswordResetEmail(this.state.email).then(function() {
      // Email sent.
      ToastsStore.success("Se envío un correo");
      comp.setState({ open: false });
      

    }).catch(function(error) {
      
      console.log(error);
      ToastsStore.success("El correo no esta registrado");
    

    });





    
  }





}

const enhance = compose(

	withFirebase,
	firebaseConnect(),
	firestoreConnect()
  )
  
  
  
  export default enhance(Login);
