
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const IMAGECHANGE = 'IMAGECHANGE'
export const SET_ROLE = 'SET_ROLE'


export function receiveLogin() {
  return {
    type: LOGIN_SUCCESS,
     isAuthenticated: true
  }
}

export function receiveLoginRole(role) {
  localStorage.setItem('role', role);
 
  
  return {
    type: SET_ROLE,
    isAuthenticated: true,
    role:role
  }
}
  
export function receiveLogout() {
  localStorage.removeItem('user_token');
  localStorage.removeItem('role');
  localStorage.removeItem('image');
  localStorage.removeItem('role');
    return {
      type: LOGOUT_SUCCESS,
      isFetching: false,
      isAuthenticated: false
    }
 }
  
 export function changeImage(image) {
   try {
    localStorage.setItem('image', image);
 
   } catch (error) {
     
   }
 
 
    return {
      type: IMAGECHANGE,
      imageUser: image
    }
 }





 export function receiveRole(role) {
  localStorage.setItem('role', role);
  return {
    type: SET_ROLE,
    role:role 
  }
}
  
 

  



 