import React from 'react'
import Lottie from 'react-lottie';
import  animationData from './loader2.json'
 
export default class LottieControl extends React.Component {
  state = {visible:true,isStopped: false, isPaused: false};
 
  render() {

 
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
 

       

    if(this.props.visibleLoading!=null && this.props.visibleLoading){

    return (<div style={{position:'fixed',  top:'calc( 50% - 40px)', left: 'calc( 50% - 40px)', zIndex:200}}>

      <Lottie 
              options={defaultOptions}
              height={70}
              width={70}
              isStopped={this.state.isStopped}
              isPaused={this.state.isPaused}/>
       </div>);

    }else{
     return null; 
    }
  }

  

  

  componentDidMount(){

      
  }




}
