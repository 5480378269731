import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import firebase from "firebase";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { reduxFirestore, firestoreReducer } from 'redux-firestore'
import { reactReduxFirebase, firebaseReducer, getFirebase } from 'react-redux-firebase';
import config from './firebase/config';
import asyncAuth from './reducers';
import reducer from './reducers/value'
import reducer2 from './reducers/value2'

//configuracion de almacenes


  const rrfConfig = {
      userProfile: 'usuarios', 
      useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
      enableLogging: false
  };
  firebase.initializeApp(config);

  const middleware = [
    thunk.withExtraArgument(getFirebase)
    // This is where you add other middleware like redux-observable
  ];

  const createStoreWithFirebase = compose(
    applyMiddleware(...middleware),
    reduxFirestore(firebase), // reduxFirestore(firebase) // <- needed if using firestore
    reactReduxFirebase(firebase, rrfConfig) // firebase instance as first argument
  )(createStore);

  const rootReducer = combineReducers({
    reducer,
    reducer2,
    asyncAuth,
    firebase: firebaseReducer,
    firestore: firestoreReducer // <- needed if using firestore
  });

  // Create store with reducers and initial state

  const initialState = {}

  const store = createStoreWithFirebase(rootReducer, initialState);

ReactDOM.render(( <Provider store={store}>
                     <App/>
                  </Provider>), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
