
require('dotenv').config();

const apiKey = process.env.REACT_APP_PROD_API_KEY;
const authDomain = process.env.REACT_APP_PROD_AUTH_DOMAIN;
const databaseURL = process.env.REACT_APP_PROD_DATABASE_URL;
const projectId = process.env.REACT_APP_PROD_PROJECT_ID;
const storageBucket = process.env.REACT_APP_PROD_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_PROD_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_PROD_ID;
const measurementId = process.env.REACT_APP_PROD_MEASUREMENT_ID;

   var devConfig = {
    apiKey,
    authDomain,
    databaseURL,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId
  }; 



  
export default devConfig;

