import React, { Component } from 'react';
import { Route, Redirect } from "react-router-dom";

import  DefaultLayout from  '../containers/DefaultLayout';



class PrivateRoute extends Component { 
  


  
  
  
  render(){
   
    return(
        <Route  path={this.props.path}  render={props => (this.props.authenticated?   <DefaultLayout {...props}  />:  <Redirect to="/login" />) } />
    );
  }

}


export default PrivateRoute;