import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import {navEstudiante,navAdminMaster, navAdmin, navInit,navProfesor, navProfesorTutor  } from '../../_nav';
// routes config
import {routesMaster, routesAdmin, routesEstudiante, routesNull, routesProfesor, routesProfesorTutor} from '../../routes';
import { firebaseConnect, withFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import {
  compose
} from 'recompose';
import LottieControl from '../../components/LottieControl';

const DefaultHeader = React.lazy(() => import('./DefaultHeader'));


class DefaultLayout extends Component {
  loading = () => <div className="animated fadeIn pt-1 text-center"> <LottieControl  visibleLoading={true}/>  </div>
 

  signOut(e) {
    e.preventDefault()
   

      
      this.props.firebase.auth().signOut().then(function() {
        // Sign-out successful.

        this.props.history.push('/login')

      }).catch(function(error) {
        // An error happened.
      });
  



  }

  render() {
 
 
     let aliadosNav = {
       items:[]
     };
 



    let profile_enable = (  !this.props.profile.isEmpty &&  (this.props.profile.estado===undefined || this.props.profile.estado==="Activo"));
     


    for (let index = 0; index < navEstudiante.items.length; index++) {
      let route = navEstudiante.items[index];
      if((!( route.name!==undefined &&  route.name.indexOf("Promocion")!==-1  && this.props.promociones <=0 ) && !(route.name!==undefined && route.name.indexOf("Notificacion")!==-1   && this.props.notificaciones<=0))) {
        
        aliadosNav.items.push(route);
      
      }
    }
   
   let listRotes=(!this.props.role.isEmpty && profile_enable)? ( this.props.role === "adminMaster"? routesMaster : this.props.role === "admin"? routesAdmin : (this.props.role === "estudiante"? routesEstudiante: (this.props.role === "profesor"?routesProfesor:(this.props.role === "profesorTutor"?routesProfesorTutor:routesNull)))): routesNull;
  

  if(!this.props.role.isEmpty && (this.props.role === "adminMaster" || this.props.role === "admin" || this.props.role === "profesorTutor" || this.props.role === "profesor" ||   (navEstudiante.items.length>0 && this.props.role === "estudiante"))){
  
   return (
      <div className="app" style={{background:"#ffffff"}}>
        <AppHeader fixed>
        
          <Suspense  >
            <DefaultHeader onLogout={e=>this.signOut(e)}/>
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
              <AppSidebarForm />
            <Suspense>
               <AppSidebarNav navConfig={ (!this.props.role.isEmpty && profile_enable) ?  (this.props.role === "adminMaster"? navAdminMaster : this.props.role === "admin"? navAdmin :(this.props.role === "estudiante")? navEstudiante: (this.props.role === "profesor")?navProfesor:(this.props.role === "profesorTutor")? navProfesorTutor  :  navInit) : navInit} {...this.props} router={ router}/>
            </Suspense>
            <AppSidebarFooter style={{background:"#fff"}}>
                <div style={{fontSize:"11px", marginTop:"57px", fontWeight:"300", color:"#666666"}}>
                &copy; {new Date().getFullYear()} Institución educativa Quichaya.
                </div>
            </AppSidebarFooter> 
           
          </AppSidebar>


          <main className="main">
       

            <AppBreadcrumb appRoutes={ ( this.props.role === "adminMaster"? routesMaster : this.props.role === "admin"? routesAdmin :(this.props.role === "estudiante")? routesEstudiante: (this.props.role === "profesor")?routesProfesor:(this.props.role === "profesorTutor")? routesProfesorTutor  :  routesNull)} router={router}/>
            <Container fluid>
            {this.props.loading? <LottieControl  visibleLoading={true}/>:null}

              <Suspense fallback={this.loading()}>
                 <Switch>
                  { listRotes.map((route, idx) => {
       
                 
                          return route.component ? (
                            <Route
                              key={idx}
                              path={route.path}
                              exact={route.exact}
                              name={route.name}
                              render={props => (
                                <route.component  {...props} />
                              )} />
                          ) : (null);

                     

                  })}
                  <Redirect from="/" to="/inicio" />
                </Switch>
              </Suspense>
            </Container>
          </main>
         
        </div>
       
      </div>
    );
  
}else{

  return <LottieControl  visibleLoading={true}/>;
}
  }

  
}

const enhance = compose(
  withFirebase,
  firebaseConnect(),
  connect((state) =>{ 
    const {  asyncAuth } = state
 
    const { isAuthenticated, role, loading, notificaciones, promociones } = asyncAuth;
    return({
      role:role,
      profile: state.firebase.profile,
      loading: loading,
      isAuthenticated:isAuthenticated,
      notificaciones:notificaciones,
      promociones:promociones
  })

})
)



export default  enhance(DefaultLayout);
