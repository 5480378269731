import React, { Component } from 'react';
import { HashRouter, Route, Switch,Redirect } from 'react-router-dom';
import './App.scss';
import  Login  from './views/Pages/Login/Login';
import PrivateRoute from './firebase/PrivateRoute';
import { firebaseConnect, withFirebase, firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import {
  compose
} from 'recompose';
import LottieControl from './components/LottieControl';
import {receiveRole, receiveLogin, receiveLogout, receiveLoginRole, changeImage} from './actions/actions';
import Domain from './config/Domain'

const loading = () => <div className="animated fadeIn pt-3 text-center"> <LottieControl  visibleLoading={true}/>   </div>;
// Containers
// Pages
//const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));




class App extends Component {
  state = { loading: true, authenticated: true, user: null, role:"estudiante" ,rolUser:''};

  constructor(props){
    super(props);
    this.db = this.props.firebase.firestore();
    this.auth = this.props.firebase.auth;
  }



  render() {
      if(this.state.loading){
        return loading();

      }else{
      
          return (
              <HashRouter>
                  <React.Suspense fallback={loading()}>
                    <Switch>
                      <Route exact path="/login" name="Login" render={props => this.state.authenticated? <Redirect to="/" /> : <Login {...props}/>} />
                      <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                      <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                      <PrivateRoute
                          path="/"
                          authenticated={this.state.authenticated}
                      />
                    </Switch>
                  </React.Suspense>
              </HashRouter>
          );
      }
  }



componentWillMount(){
  console.clear();
  this.validateSesion();
  this.listenerUserFirebaseChange();
  this.props.firestore.enablePersistence()
  .catch(function(err) {
      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });
    

  this.props.firebase
    .auth()
    .signInAnonymously()
    .then(() => {
      console.log("Correcto");
      let userCurrent = this.props.firebase
      .auth().currentUser;
    let comp = this;
    userCurrent.getIdToken().then(function (data) {
      console.log(data)
    });
    })
    .catch((error) => {
      console.log(error.code)
      console.log(error.message)
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });

  }

  componentDidMount(){

    const { dispatch } = this.props;
    dispatch({ type:"LOADING", loading: false });

    

  }


  validateSesion(){
    const { dispatch } = this.props;
    dispatch({ type:"LOADING", loading: true });
   if(this.props.isAuthenticated.isEmpty ===false){
    //if(this.props.isAuthenticated ){
      const { dispatch } = this.props;
      if(this.props.auth.photoURL!=null){
        dispatch(changeImage(this.props.auth.photoURL));
      }
    //}



    let userCurrent = this.props.firebase.auth().currentUser;

      if(userCurrent !== null){
        this.setState({
          authenticated: true,
          currentUser: userCurrent,
          loading: false
        },()=>{
        });

       
      }else{
        this.setState({authenticated:false});
      }
    }
  }


  listenerUserFirebaseChange(){
    this.props.firebase.auth().onAuthStateChanged(user => {
      



      if (user!==null) {
        this.state.loading= true; 
        let userCurrent = this.props.firebase.auth().currentUser;
        if(userCurrent !== null){
             this.validateRole(userCurrent, user);
        }


      }else{


        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false
        });
      }
    });

  }

  




   validateRole(userCurrent, user){

    const { dispatch } = this.props;
         let comp = this;
          userCurrent.getIdTokenResult().then((idTokenResult) => {

            if(userCurrent.photoURL!==null){
               dispatch(changeImage(userCurrent.photoURL));
            }

            if((idTokenResult.claims.adminMaster===undefined || idTokenResult.claims.admin===undefined || idTokenResult.claims.estudiante===undefined  || idTokenResult.claims.profesor===undefined || idTokenResult.claims.profesorTutor===undefined   ) && this.props.role===""  ){
                 comp.updateRole(idTokenResult.token, user);

            }else{  


                    let role= idTokenResult.claims.adminMaster?"adminMaster":(idTokenResult.claims.admin?"admin":idTokenResult.claims.estudiante?"estudiante": idTokenResult.claims.profesor?"profesor":idTokenResult.claims.profesorTutor?"profesorTutor":"");

                    //console.log("role!!!", role);

                    if(role==="adminMaster" || role==="admin" || role==="estudiante"  || this.props.role==="estudiante" || role==="profesorTutor" || role==="profesor" || this.props.role=="profesor" ){
                      dispatch(receiveLogin());
                      localStorage.setItem('user_token', JSON.stringify(userCurrent));
                      
                     if(role!=""){
                        dispatch(receiveRole(role));
                     }


                      this.setState({
                        authenticated: true,
                        currentUser: userCurrent,
                        loading: false
                      });
                      
                      

                    }else{
                      this.setState({
                        authenticated: false,
                        currentUser: null,
                        loading: false
                      });
                      localStorage.removeItem('user_token');

                    
                      this.logout();

                     }
             }
            })
          .catch((error) => {
           // console.log(error);
          });
        


   }


 logout(){
   const { dispatch } = this.props;
   this.props.firebase.auth().signOut().then(function() {
    // Sign-out successful.
    dispatch(receiveLogout()); 
    this.props.history.push('/login')

  }).catch(function(error) {
    // An error happened.
  });
 }



componentWillUnmount() {
  if(this.unsubscribe!=null)
    this.unsubscribe();
}





 updateRole(token,user){
    this.db.collection('usuarios').doc(user.uid).get().then(snap=>{
      let rol = snap.data().rol
      let email= user.email
      const { dispatch } = this.props;
      let userCurrent = this.props.firebase.auth().currentUser;
      fetch(Domain.urlService+'/api/v1/usuario/rol', { 
        method: 'post', 
        headers: new Headers({
          'Authorization': 'Bearer '+token, 
          'Content-Type': 'application/json'
        }), 
        body: JSON.stringify( { email: email,
        adminMaster:rol==='adminMaster'?true:false,
        admin:rol==='admin'?true:false,
        estudiante:rol==='estudiante'?true:false,
        profesor:rol==='profesor'?true:false,
        profesorTutor:rol==='profesorTutor'?true:false
      })
      }).then((response) => response.json())
      .then((responseJson) => {
        //console.log("ResponseJson rol",responseJson); 
        this.props.firebase.auth().currentUser.getIdToken()
        .then((token) => {
          dispatch(receiveLoginRole(rol));
          setTimeout(()=>this.validateRole(userCurrent, userCurrent),1000)

        })
        .catch((err) => {
            //console.error('Error refreshing id token', err)
        })

      }); 
})
} 



}



const enhance = compose(

  withFirebase,
  firebaseConnect(),
  firestoreConnect()
  ,connect((state) =>{ 
    const {  asyncAuth } = state
    const { isAuthenticated ,loading, role} = asyncAuth;
     
      return({
          isAuthenticated,
          auth: state.firebase.auth,
          profile: state.firebase.profile,
          loading: loading,
          role:role
      });
    }
  )
)



export default enhance(App);
