import {type as storeValueType} from '../actions/storeValue'

const defaultState = '';

function reducer(state=defaultState, {type,payload}){
    switch(type){
        case storeValueType:{
            return payload;
        }
        default:
            return state;
    }
}

export default reducer;