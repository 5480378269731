
export const navInit = {
  items: [
    {
      name: 'Inicio',
      url: '/inicio',
      icon: 'house-icon'
    }
  ]
  }


export const navAdminMaster = {
  items: [
    {
      name: 'Inicio',
      url: '/Inicio',// aqui va /inicio
      icon: 'house-icon'
    },
    {
      name: 'Gestionar Estudiantes',
      icon: 'student-icon',
      children: [
        {
          name: 'Lista de estudiantes',
          url: '/estudiantes',
          icon: 'list-aliados-icon',
        },
        {
          name: 'Crear estudiante',
          url: '/estudiantes/crear',
          icon: 'create-icon',
        }
      ]
    },
   {
      name: 'Gestionar Admin',
      icon: 'admin-icon',
      children: [
        {
          name: 'Lista de usuarios plataforma',
          url: '/usersPlatform',
          icon: 'list-aliados-icon',
        },
        {
          name: 'Crear usuarios plataforma',
          url: '/usersPlatform/create',
          icon: 'create-icon',
        }
      ]
    },
   
    {
      name: 'Gestionar Profesores',
      icon: 'teacher-icon',
      children: [
        {
          name: 'Lista de Profesores',
          url: '/profesores',
          icon: 'list-aliados-icon',
        },
        {
          name: 'Crear Profesor',
          url: '/profesores/crear',
          icon: 'create-icon',
        }
      ]
    },
  
    {
      name: 'Cursos,materias,hilos',
      icon: 'flow-icon',
      children: [
        {
          name: 'Gestionar cursos',
          url: '/cursos/editar',
          icon: 'list-aliados-icon',
        },
        {
          name: 'Gestionar materias',
          url: '/materias/editar',
          icon: 'add-icon',
        },
        {
          name: 'Gestionar Hilos',
          url: '/hilos/editar',
          icon: 'diag-icon',
        },
        {
          name: 'Enlazar Hilos',
          url: '/hilos/enlazar',
          icon: 'link-icon',
        }
      ]
    },
    {
      name: 'Gestionar Web',
      url: '/web',
      icon: 'web-icon',
    },

    {
      name: 'Periodos Académicos',
      url: '/fecha_periodos',
      icon: 'menu-icon',
    },
    {
      name: 'Promover Estudiantes',
      url: '/promover',
      icon: 'promote-icon',
    },
    {
      name: 'Reportes',
      icon: 'list-aliados-icon',
      children: [
        {
          name: 'Estudiantes',
          url: '/reportes/estudiantes',
          icon: 'student-icon',
        },
        {
          name: 'Profesores',
          url: '/reportes/profesores',
          icon: 'teacher-icon',
        },
        {
          name: 'Notas',
          url: '/reportes/notaEstudiante',
          icon: 'exam-icon',
        }
      ]
    },
    {
      name: 'Votaciones',
      icon: 'vote-icon',
      children: [
        {
          name: 'Crear Candidatos',
          url: '/votaciones/crearCandidatos',
          icon: 'create-icon',
        },
        {
          name: 'Conteo',
          url: '/votaciones/resultadosVotaciones',
          icon: 'count-icon',
        }
      ]
    },
    {
      name: 'Evaluacion docente',
      icon: 'vote-icon',
      children: [
        {
          name: 'Crear plantilla',
          url: '/evaluacion/crearPlantilla',
          icon: 'add-icon',
        },
        {
          name: 'Resultados',
          url: '/evaluacion/resultados',
          icon: 'stadistics-icon',
        },
        {
          name: 'Lista evaluadores',
          url: '/evaluacion/lista',
          icon: 'list-aliados-icon',
        }
      ]
    },
    {
      divider: true,
    },
  ],
};

export const navAdmin = {
  items: [
    {
      name: 'Inicio',
      url: '/Inicio',// aqui va /inicio
      icon: 'house-icon'
    },
    {
      name: 'Gestionar Estudiantes',
      icon: 'student-icon',
      children: [
        {
          name: 'Lista de estudiantes',
          url: '/estudiantes',
          icon: 'list-aliados-icon',
        },
        {
          name: 'Crear estudiante',
          url: '/estudiantes/crear',
          icon: 'create-icon',
        }


      ]
    },
    {
      name: 'Gestionar Profesores',
      icon: 'teacher-icon',
      children: [
        {
          name: 'Lista de Profesores',
          url: '/profesores',
          icon: 'list-aliados-icon',
        },
        {
          name: 'Crear Profesor',
          url: '/profesores/crear',
          icon: 'create-icon',
        }
      ]
    },
  
    {
      name: 'Cursos,materias,hilos',
      icon: 'flow-icon',
      children: [
        {
          name: 'Gestionar cursos',
          url: '/cursos/editar',
          icon: 'list-aliados-icon',
        },
        {
          name: 'Gestionar materias',
          url: '/materias/editar',
          icon: 'add-icon',
        },
        {
          name: 'Gestionar Hilos',
          url: '/hilos/editar',
          icon: 'diag-icon',
        },
        {
          name: 'Enlazar Hilos',
          url: '/hilos/enlazar',
          icon: 'link-icon',
        }


      ]
    },
    
    {
      name: 'Periodos Académicos',
      url: '/fecha_periodos',
      icon: 'menu-icon',
    },
    {
      name: 'Promover Estudiantes',
      url: '/promover',
      icon: 'promote-icon',
    },
    {
      name: 'Reportes',
      icon: 'stadistics-icon',
      children: [
        {
          name: 'Estudiantes',
          url: '/reportes/estudiantes',
          icon: 'student-icon',
        },
        {
          name: 'Profesores',
          url: '/reportes/profesores',
          icon: 'teacher-icon',
        },
        {
          name: 'Notas',
          url: '/reportes/notaEstudiante',
          icon: 'exam-icon',
        }
      ]
    },
    {
      divider: true,
    }
  ],
};

export const navProfesor = {
  items: [
    {
      name: 'Inicio',
      url: '/periodos',
      icon: 'house-icon'
    },
    {
      name: 'Gestionar desempeño',
      url: { 
        pathname: '/periodos', 
        state: { fromDesempeno: true }
      },
      icon: 'flow-icon',
    },
    {
      name: 'Subir notas y faltas',
      url: { 
        pathname: '/periodosN', 
        state: { fromNotas: true }
      },
      icon: 'note-icon'
    },
    {
      divider: true,
    },

 
  ],
};

export const navProfesorTutor = {
  items: [
    {
      name: 'Inicio',
      url: '/periodos',
      icon: 'house-icon'
    },
    {
      name: 'Gestionar desempeño',
      url: { 
        pathname: '/periodos', 
        state: { fromDesempeno: true }
      },
      icon: 'flow-icon',
    },
    {
      name: 'Subir notas y faltas',
      url: { 
        pathname: '/periodosN', 
        state: { fromNotas: true }
      },
      icon: 'note-icon'
    },
    {
      name: 'Boletines',
      url: { 
        pathname: '/periodosB', 
        state: { fromBoletin: true }
      },
      icon: 'exam-icon'
    },
    {
      name: 'Subir Horario',
      url: '/subirHorario', 
      icon: 'upload-icon'
    }, 
    {
      divider: true,
    },

 
  ],
};




export const navEstudiante={
  items: [
    {
      name: 'Inicio',
      url: '/inicio',
      icon: 'house-icon'
    },
    {
      name: 'Consultar notas',
      url: '/notas',
      icon: 'exam-icon'
    },
    {
      name: 'Descargar horario',
      url: '/verHorario',
      icon: 'download-icon'
    },
    {
      name: 'Votaciones',
      url: '/votar',
      icon: 'vote-icon'
    },
    {
      name: 'Evaluar docente',
      url: '/evaluar',
      icon: 'vote-icon'
    },
    {
      divider: true,
    }

 
  ],
};


