





const AuthState = {
  isAuthenticated: localStorage.getItem('user_token') ? true : false,
  /* role:localStorage.getItem('role')? localStorage.getItem('role') : "", */
  role:'adminMaster',//iba admin aqui
  photoURL:localStorage.getItem('image')? localStorage.getItem('image') : "/assets/img/avatars/1.png",
  loading:false

};




const asyncAuth= (state = AuthState, action) => {
  switch (action.type) {
      case "LOGIN_SUCCESS":
        return Object.assign({}, state, {
           isAuthenticated: true,
         })
      case "SET_ROLE":
      
        return Object.assign({}, state, {
           isAuthenticated: true,
           role: action.role
        })

      case "IMAGECHANGE":
       return Object.assign({}, state, {
        photoURL: action.imageUser
       })
      case "LOGOUT_SUCCESS":
      
        return Object.assign({}, state, {
          isAuthenticated: false,
          role: "",
          photoURL:"/assets/img/avatars/1.png"
        })
      case "LOADING":
       
      return Object.assign({}, state, {
        loading:action.loading
      })

        
       default:
        return state;
    
    


    }
  

};



export default asyncAuth;