import React from 'react';

const Dashboard = React.lazy(() => import('./views/Dashboard'));
const Users = React.lazy(() => import('./views/Users/Users'));
const User = React.lazy(() => import('./views/Users/User'));
const VerAdmins = React.lazy(() => import('./views/Users/VerAdmins'));
const CreateUser = React.lazy(() => import('./views/Users/CreateUser'));

const Teacher = React.lazy(() => import('./views/Users/Teachers'));
const CreateTeacher = React.lazy(() => import('./views/Users/CreateTeacher'));
const ViewTeacher = React.lazy(() => import('./views/Users/ViewTeacher'));

const UsersPlatform = React.lazy(() => import('./views/Users/ListPlatformUsers'));
const CreateUserPlatform = React.lazy(() => import('./views/Users/CreateplatformUser'));

const Profile = React.lazy(() => import('./views/Profile/Profile'));
const EditPassword = React.lazy(() => import('./views/Profile/EditPassword'));
const createCursos = React.lazy(() => import('./views/Cursos/Create'));
const createMaterias = React.lazy(() => import('./views/Cursos/CreateSubject'));
const createHilos = React.lazy(() => import('./views/Cursos/CreateHilos'));
const hilosMaterias = React.lazy(() => import('./views/Cursos/HilosMaterias'));


const Inicio = React.lazy(() => import('./views/Inicio'));

const crearDesempeno = React.lazy(() => import('./views/profesor/SelectSubject')) 
const crearNotas = React.lazy(() => import('./views/profesor/createNotas')) 
const crearBoletin = React.lazy(() => import('./views/profesor/createBoletin')) 
const Periodos = React.lazy(() => import('./views/Users/Periodos'))
const SelectPeriodos = React.lazy(() => import('./views/logistica/SelectPeriodos'))
const SelectPeriodosBoletin = React.lazy(() => import('./views/logistica/SelectPeriodosBoletin'))

const notasEstudiante = React.lazy(() => import('./views/estudiante/notasEstudiante')) 
const promoverEstudiantes = React.lazy(() => import('./views/logistica/upgradeStudent')) 
const subirHorario = React.lazy(() => import('./views/profesor/subirHorario')) 
const verHorario = React.lazy(() => import('./views/estudiante/verHorario')) 
const ReporteGeneral = React.lazy(() => import('./views/reportes/reporteGeneral')) 
const ReporteGeneralProfes = React.lazy(() => import('./views/reportes/reporteGeneralProfes')) 
const ReporteNotas = React.lazy(() => import('./views/reportes/reporteNota')) 
const VerNotas = React.lazy(() => import('./views/reportes/VerNotas'));
const crearCandidatos = React.lazy(() => import('./views/votaciones/crearCandidatos'));
const votarCandidatos = React.lazy(() => import('./views/votaciones/votarCandidatos'));
const resultadosVotaciones = React.lazy(() => import('./views/votaciones/resultadosVotaciones'));

const crearEvaluacion = React.lazy(() => import('./views/evaluacionDocente/crearEvaluacion'));
const evaluarDocente = React.lazy(() => import('./views/evaluacionDocente/evaluarDocente'));
const resultadosEvaluacion = React.lazy(() => import('./views/evaluacionDocente/resultadosEvaluacion'));
const listaEvaluadores = React.lazy(() => import('./views/evaluacionDocente/listaEvaluadores'));

const modificarWeb = React.lazy(() => import('./views/logistica/modificarWeb'));



export const routesMaster = [
  { path: '/inicio', name: 'Inicio', component:Dashboard}, //Dashboard
  { path: '/estudiantes/crear', exact: true,  name: 'Crear Estudiante', component: CreateUser },
  { path: '/estudiantes', exact: true,  name: 'Gestion Estudiantes', component: Users },
  { path: '/usersPlatform/create', exact: true,  name: 'Crear Usuario plataforma', component: CreateUserPlatform },
  { path: '/usersPlatform', exact: true,  name: 'Usuarios plataforma', component: UsersPlatform },
  { path: '/usersPlatform/:id', exact: true, name: 'Detalles Administradores', component: VerAdmins },
  { path: '/perfil/editar', exact: true,  name: 'perfil / editar', component: Profile },
  { path: '/perfil/editar-password', exact: true,  name: 'perfil / contraseña', component: EditPassword },
  { path: '/cursos/editar', exact: true,  name: 'Gestionar Cursos', component: createCursos },
  { path: '/materias/editar', exact: true,  name: 'Gestionar Materias', component: createMaterias },
  { path: '/hilos/editar', exact: true,  name: 'Gestionar Hilos', component: createHilos },
  { path: '/hilos/enlazar', exact: true,  name: 'Enlazar hilos', component: hilosMaterias },
  { path: '/estudiantes/:id', exact: true, name: 'Detalles Estudiante', component: User },
  { path: '/profesores/crear', exact: true,  name: 'Crear', component: CreateTeacher },
  { path: '/profesores', exact: true,  name: 'Gestion Profesores', component: Teacher },
  { path: '/profesores/:id', exact: true,  name: 'Ver y editar', component: ViewTeacher },
  { path: '/fecha_periodos', exact: true,  name: 'Gestionar Periodos', component: Periodos },
  { path: '/promover', exact: true,  name: 'Promover Estudiantes', component: promoverEstudiantes },
  { path: '/reportes/estudiantes', exact: true,  name: 'Reporte Estudiantes', component: ReporteGeneral },
  { path: '/reportes/profesores', exact: true,  name: 'Reporte Profesores', component: ReporteGeneralProfes },
  { path: '/reportes/notaEstudiante', exact: true,  name: 'Reporte Notas', component: ReporteNotas },
  { path: '/reportes/notaEstudiante/:id', exact: true, name: 'Detalles notas estudiante', component: VerNotas },
  { path: '/votaciones/crearCandidatos', exact: true, name: 'Crear Candidatos', component: crearCandidatos },
  { path: '/votaciones/resultadosVotaciones', exact: true, name: 'Crear Candidatos', component: resultadosVotaciones },
  { path: '/evaluacion/crearPlantilla', exact: true, name: 'Crear plantilla', component: crearEvaluacion },
  { path: '/evaluacion/resultados', exact: true, name: 'Resultados', component: resultadosEvaluacion },
  { path: '/evaluacion/lista', exact: true, name: 'Evaluadores', component: listaEvaluadores },
  { path: '/web', exact: true, name: 'Modificar WEB', component: modificarWeb },
];


export const routesAdmin = [
  { path: '/inicio', name: 'Inicio', component: Dashboard}, //Dashboard
  { path: '/estudiantes/crear', exact: true,  name: 'Crear Estudiante', component: CreateUser },
  { path: '/estudiantes', exact: true,  name: 'Gestion Estudiantes', component: Users },
  { path: '/perfil/editar', exact: true,  name: 'perfil / editar', component: Profile },
  { path: '/perfil/editar-password', exact: true,  name: 'perfil / contraseña', component: EditPassword },
  { path: '/cursos/editar', exact: true,  name: 'Gestionar Categorías', component: createCursos },
  { path: '/materias/editar', exact: true,  name: 'Gestionar Categorías', component: createMaterias },
  { path: '/hilos/editar', exact: true,  name: 'Gestionar Hilos', component: createHilos },
  { path: '/hilos/enlazar', exact: true,  name: 'Enlazar hilos', component: hilosMaterias },
  { path: '/estudiantes/:id', exact: true, name: 'Detalles Estudiante', component: User },
  { path: '/profesores/crear', exact: true,  name: 'Crear', component: CreateTeacher },
  { path: '/profesores', exact: true,  name: 'Gestion Profesores', component: Teacher },
  { path: '/profesores/:id', exact: true,  name: 'Ver y editar', component: ViewTeacher },
  { path: '/fecha_periodos', exact: true,  name: 'Gestionar Periodos', component: Periodos },
  { path: '/promover', exact: true,  name: 'Promover Estudiantes', component: promoverEstudiantes },
  { path: '/reportes/estudiantes', exact: true,  name: 'Reporte Estudiantes', component: ReporteGeneral },
  { path: '/reportes/profesores', exact: true,  name: 'Reporte Profesores', component: ReporteGeneralProfes },
  { path: '/reportes/notaEstudiante', exact: true,  name: 'Reporte Notas', component: ReporteNotas },
  { path: '/reportes/notaEstudiante/:id', exact: true, name: 'Detalles notas estudiante', component: VerNotas },
];




export const routesProfesor = [
  { path: '/inicio', name: 'Inicio', component: Dashboard },//estaba Users
  { path: '/perfil/editar', exact: true,  name: 'perfil / editar', component: Profile },
  { path: '/perfil/editar-password', exact: true,  name: 'perfil / contraseña', component: EditPassword },
  { path: '/periodos/desempeño', exact: true,  name: 'Redectar el desempeño', component: crearDesempeno },
  { path: '/periodosN/crearNotas', exact: true,  name: 'Crear notas estudiantes', component: crearNotas },
  { path: '/periodos', exact: true,  name: 'Seleccion de periodos', component: SelectPeriodos }, 
  { path: '/periodosN', exact: true,  name: 'Seleccion de periodos', component: SelectPeriodos }, 

];
export const routesProfesorTutor = [
  { path: '/inicio', name: 'Inicio', component: Dashboard },//estaba Dashbord
  { path: '/perfil/editar', exact: true,  name: 'perfil / editar', component: Profile },
  { path: '/perfil/editar-password', exact: true,  name: 'perfil / contraseña', component: EditPassword },
  { path: '/periodos/desempeño', exact: true,  name: 'Redectar el desempeño', component: crearDesempeno },
  { path: '/periodosN/crearNotas', exact: true,  name: 'Crear notas estudiantes', component: crearNotas },
  { path: '/periodosB/boletin', exact: true,  name: 'Crear boletin estudiante', component: crearBoletin },
  { path: '/periodos', exact: true,  name: 'Seleccion de periodos', component: SelectPeriodos }, 
  { path: '/periodosN', exact: true,  name: 'Seleccion de periodos', component: SelectPeriodos }, 
  { path: '/periodosB', exact: true,  name: 'Seleccion de periodos', component: SelectPeriodosBoletin }, 
  { path: '/subirHorario', exact: true,  name: 'Subir Horario', component: subirHorario }, 

];





// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
export const routesEstudiante = [
  { path: '/inicio', name: 'Inicio', component: Dashboard },//estaba Dashbord
  { path: '/notas', exact: true, name: 'Consultar notas', component: notasEstudiante },
  { path: '/perfil/editar-password', exact: true,  name: 'perfil / contraseña', component: EditPassword },
  { path: '/verHorario', exact: true,  name: 'Descargar Horario', component: verHorario }, 
  { path: '/votar', exact: true,  name: 'Votar por candidato', component: votarCandidatos }, 
  { path: '/evaluar', exact: true,  name: 'Evaluar docente', component: evaluarDocente }, 

];




export const routesNull = [
  { path: '/inicio', name: 'Inicio', component: Inicio },
  { path: '/perfil/editar', exact: true,  name: 'perfil / editar', component: Profile },
  { path: '/perfil/editar-password', exact: true,  name: 'perfil / contraseña', component: EditPassword }
 
]



